import React from 'react';

import Organization from "../../constants/organization";
import providerType from "../../constants/providerType";

const MobileToggle = ({
                        showContractLevel,
                        currentQuotation,
                        isSalesValue,
                        mobilePerLocation,
                        showMobileYears,
                        showPerLocation,
                        inputsDisabled,
                        isExistingType,
                        isVerlengenType,
                        usingExistingData,
                        updateQuotationProperty,
                        getMobileYears,
                        getExistingMobileData,
                        resetExistingMobileData,
                        vodafoneMobileYears,
                        isDataSaved,
                        company,
                        updateMobileAddons,
                        hasActiveAddons,
                        mainActionsView,
                        switchExistingDataTableActionsView,
                        isOdidoPortfolio,
                        isKpnProvider
                      }) => (
  <React.Fragment>{
    (
      <div className="col s12">
        <div className="row">
          <h1 className={'ratio-section-title'}>
              <span className={'ratio-section-title'}>
                  <i className="small material-icons left ratio-section-title">phone_android</i>
                {isVerlengenType && !isOdidoPortfolio ? 'Mobiele telefonie instellen' : 'Wenst u mobiele telefonie?'}
              </span>
          </h1>
          {(showContractLevel && !isVerlengenType && company !== Organization.PIT) || isOdidoPortfolio ?
            <div>
              {currentQuotation.provider === providerType.VODAFONE ?
                (
                  vodafoneMobileYears.map((el, index) =>
                    <button
                      key={el}
                      disabled={inputsDisabled}
                      name="mobileOfferYear"
                      value={el}
                      style={index < vodafoneMobileYears.length - 1 ? {marginRight: 15} : {}}
                      className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${currentQuotation.mobileOfferYear === el ? "active" : ""}`}
                      onClick={updateQuotationProperty}
                    >
                      {el}
                    </button>
                  )
                ) :
                (!isSalesValue ?
                    (
                      mobilePerLocation ? (
                        <div>
                          <button
                            className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${showMobileYears && showPerLocation && currentQuotation.mobileNeeded ? "active" : ""}`}
                            style={{marginRight: 15}}
                            onClick={() => getMobileYears(true, true, currentQuotation.mobileNeeded)}
                            disabled={inputsDisabled}
                          >
                            Ja, per locatie
                          </button>
                          <button
                            className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${!showPerLocation && currentQuotation.mobileNeeded ? "active" : ""}`}
                            onClick={() => getMobileYears(false, true, currentQuotation.mobileNeeded)}
                            disabled={inputsDisabled}
                          >
                            Ja, per offerte
                          </button>
                        </div>
                      ) : (
                        <div>
                          {
                            isOdidoPortfolio ?
                              <button
                                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${(currentQuotation.mobileOfferYear) ? "active" : ""}`}
                                onClick={() => getMobileYears()}
                                disabled={inputsDisabled}
                              >
                                Ja, Mobiele telefonie instellen
                              </button>
                              :
                              <button
                                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${(!showPerLocation && currentQuotation.mobileNeeded || currentQuotation.mobileOfferYear) ? "active" : ""}`}
                                onClick={() => getMobileYears(false, true, currentQuotation.mobileNeeded)}
                                disabled={inputsDisabled}
                              >
                                Ja, Mobiele telefonie instellen
                              </button>
                          }
                        </div>
                      )
                    ) : (
                      <button
                        className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${currentQuotation.mobileNeeded ? "active" : ""}`}
                        onClick={() => getMobileYears(false, true, currentQuotation.mobileNeeded)}
                        disabled={inputsDisabled}
                      >
                        Ja, toon standaard prijslijsten
                      </button>
                    )
                )
              }
            </div> : ''}
        </div>

        {((isExistingType && showMobileYears) || isVerlengenType) && !(isExistingType && company === Organization.PIT) && !isOdidoPortfolio &&
        (
          <div className="row">
            <div className="col s12" style={{display: 'flex', alignContent: 'flex-start', columnGap: '10px'}}>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${usingExistingData ? "active" : ""}`}
                onClick={getExistingMobileData} disabled={inputsDisabled}
              >
                Bestaande mobiele orders wijzigen
              </button
              >
              {
                (isVerlengenType || isExistingType) && showMobileYears &&
                <button
                  className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${usingExistingData ? "active" : ""}`}
                  onClick={() => switchExistingDataTableActionsView(mainActionsView)}
                >
                  {mainActionsView ? 'Producten toevoegen aan bestaand order' : 'Bestaande orders wijzigen'}
                </button>
              }
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${usingExistingData === false ? "active" : ""}`}
                onClick={() => resetExistingMobileData()} disabled={inputsDisabled || (isVerlengenType && !isDataSaved)}
              >
                Nieuwe mobiele producten bestellen
              </button>
            </div>
              {isKpnProvider &&
                <div className="col s12">
                  <div className="row" style={{paddingTop: '20px'}}>
                    <div className="line tussenkop">Stel hier het gewenste mobiele opties in voor deze klant</div>
                  </div>
                    <div className="row">
                        <button
                            className={"btn doceri-btn-left " + (hasActiveAddons ? 'ratio-btn white-text active' : 'ratio-btn  ratio-btn-white-right') + (inputsDisabled ? ' isSentToGrexx' : '')}
                            style={{marginRight: 15}}
                            onClick={() => updateMobileAddons(true, false)}
                            disabled={inputsDisabled}
                        >
                            Alle mogelijke voordeelmodules activeren
                        </button>
                        <button
                            className={"btn doceri-btn-left " + (!hasActiveAddons ? 'ratio-btn white-text active' : 'ratio-btn  ratio-btn-white-right') + (inputsDisabled ? ' isSentToGrexx' : '')}
                            onClick={() => updateMobileAddons(false, false)}
                            disabled={inputsDisabled}
                        >
                            Alle voordeelmodules opzeggen
                        </button>
                    </div>
                </div>
              }
          </div>
        )
        }
      </div>
    )}
  </React.Fragment>
);

export default MobileToggle
